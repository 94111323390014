import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_swplugin_3cb89dd5 from 'nuxt_plugin_swplugin_3cb89dd5' // Source: ./sw.plugin.js (mode: 'client')
import nuxt_plugin_nuxticons_5907588c from 'nuxt_plugin_nuxticons_5907588c' // Source: ./nuxt-icons.js (mode: 'all')
import nuxt_plugin_libmodulepluginclient1071d3a3_5ef282b7 from 'nuxt_plugin_libmodulepluginclient1071d3a3_5ef282b7' // Source: ./lib.module.plugin.client.1071d3a3.js (mode: 'client')
import nuxt_plugin_toast_4b8ca2eb from 'nuxt_plugin_toast_4b8ca2eb' // Source: ./toast.js (mode: 'client')
import nuxt_plugin_cookieuniversalnuxt_968c0438 from 'nuxt_plugin_cookieuniversalnuxt_968c0438' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_axios_7f5c563c from 'nuxt_plugin_axios_7f5c563c' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_breakpoints_4655f96c from 'nuxt_plugin_breakpoints_4655f96c' // Source: ../plugins/breakpoints (mode: 'all')
import nuxt_plugin_filters_98405076 from 'nuxt_plugin_filters_98405076' // Source: ../plugins/filters (mode: 'all')
import nuxt_plugin_directives_d0867c0c from 'nuxt_plugin_directives_d0867c0c' // Source: ../plugins/directives (mode: 'all')
import nuxt_plugin_loader_52e949ee from 'nuxt_plugin_loader_52e949ee' // Source: ../plugins/loader (mode: 'all')
import nuxt_plugin_bugsnag_170b7002 from 'nuxt_plugin_bugsnag_170b7002' // Source: ../plugins/bugsnag (mode: 'all')
import nuxt_plugin_axios_3566aa80 from 'nuxt_plugin_axios_3566aa80' // Source: ../plugins/axios (mode: 'all')
import nuxt_plugin_vuetify_165b1c58 from 'nuxt_plugin_vuetify_165b1c58' // Source: ../plugins/vuetify (mode: 'all')
import nuxt_plugin_luxon_3432ef10 from 'nuxt_plugin_luxon_3432ef10' // Source: ../plugins/luxon (mode: 'all')
import nuxt_plugin_localeforage_3d0fc8e6 from 'nuxt_plugin_localeforage_3d0fc8e6' // Source: ../plugins/localeforage (mode: 'all')
import nuxt_plugin_vuelidate_4345260a from 'nuxt_plugin_vuelidate_4345260a' // Source: ../plugins/vuelidate (mode: 'all')
import nuxt_plugin_ntfu_6a84318b from 'nuxt_plugin_ntfu_6a84318b' // Source: ../plugins/ntfu (mode: 'all')
import nuxt_plugin_barcode_5a25c76c from 'nuxt_plugin_barcode_5a25c76c' // Source: ../plugins/barcode (mode: 'all')
import nuxt_plugin_preload_350ed446 from 'nuxt_plugin_preload_350ed446' // Source: ../plugins/preload.client (mode: 'client')
import nuxt_plugin_offline_62608b2c from 'nuxt_plugin_offline_62608b2c' // Source: ../plugins/offline.client (mode: 'client')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule
const baseStoreOptions = { preserveState: process.client }

function registerModule (path, rawModule, options = {}) {
  return originalRegisterModule.call(this, path, rawModule, { ...baseStoreOptions, ...options })
}

async function createApp(ssrContext, config = {}) {
  const router = await createRouter(ssrContext)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"htmlAttrs":{"lang":"nl"},"titleTemplate":"%s ~ NTFU","meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":""},{"name":"apple-mobile-web-app-title","hid":"apple-mobile-web-app-title","content":"Scan+Go"},{"name":"og:title","hid":"og:title","content":"Scan+Go"},{"name":"og:description","hid":"og:description","content":""},{"name":"og:site_name","hid":"og:site_name","content":"Scan+Go"},{"hid":"mobile-web-app-capable","name":"mobile-web-app-capable","content":"yes"},{"hid":"author","name":"author","content":"Webeau"},{"hid":"theme-color","name":"theme-color","content":"#c9dcea"},{"hid":"og:type","name":"og:type","property":"og:type","content":"website"}],"link":[{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Ffavicon.ico"},{"rel":"stylesheet","href":"https:\u002F\u002Ffonts.googleapis.com\u002Fcss?family=Barlow:400,500,600","crossorigin":"anonymous"},{"rel":"manifest","href":"\u002F_nuxt\u002Fmanifest.c899eb98.json","crossorigin":"anonymous"},{"rel":"shortcut icon","href":"\u002F_nuxt\u002Ficons\u002Ficon_64.d40jA0Ra592.png"},{"rel":"apple-touch-icon","href":"\u002F_nuxt\u002Ficons\u002Ficon_512.d40jA0Ra592.png","sizes":"512x512"}],"script":[{"src":"https:\u002F\u002Fcdnjs.cloudflare.com\u002Fajax\u002Flibs\u002Fbabel-polyfill\u002F7.12.1\u002Fpolyfill.min.js","crossorigin":"anonymous"}],"style":[],"title":"Scan+Go"},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (process.client && typeof nuxt_plugin_swplugin_3cb89dd5 === 'function') {
    await nuxt_plugin_swplugin_3cb89dd5(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxticons_5907588c === 'function') {
    await nuxt_plugin_nuxticons_5907588c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_libmodulepluginclient1071d3a3_5ef282b7 === 'function') {
    await nuxt_plugin_libmodulepluginclient1071d3a3_5ef282b7(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_toast_4b8ca2eb === 'function') {
    await nuxt_plugin_toast_4b8ca2eb(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_968c0438 === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_968c0438(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_7f5c563c === 'function') {
    await nuxt_plugin_axios_7f5c563c(app.context, inject)
  }

  if (typeof nuxt_plugin_breakpoints_4655f96c === 'function') {
    await nuxt_plugin_breakpoints_4655f96c(app.context, inject)
  }

  if (typeof nuxt_plugin_filters_98405076 === 'function') {
    await nuxt_plugin_filters_98405076(app.context, inject)
  }

  if (typeof nuxt_plugin_directives_d0867c0c === 'function') {
    await nuxt_plugin_directives_d0867c0c(app.context, inject)
  }

  if (typeof nuxt_plugin_loader_52e949ee === 'function') {
    await nuxt_plugin_loader_52e949ee(app.context, inject)
  }

  if (typeof nuxt_plugin_bugsnag_170b7002 === 'function') {
    await nuxt_plugin_bugsnag_170b7002(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_3566aa80 === 'function') {
    await nuxt_plugin_axios_3566aa80(app.context, inject)
  }

  if (typeof nuxt_plugin_vuetify_165b1c58 === 'function') {
    await nuxt_plugin_vuetify_165b1c58(app.context, inject)
  }

  if (typeof nuxt_plugin_luxon_3432ef10 === 'function') {
    await nuxt_plugin_luxon_3432ef10(app.context, inject)
  }

  if (typeof nuxt_plugin_localeforage_3d0fc8e6 === 'function') {
    await nuxt_plugin_localeforage_3d0fc8e6(app.context, inject)
  }

  if (typeof nuxt_plugin_vuelidate_4345260a === 'function') {
    await nuxt_plugin_vuelidate_4345260a(app.context, inject)
  }

  if (typeof nuxt_plugin_ntfu_6a84318b === 'function') {
    await nuxt_plugin_ntfu_6a84318b(app.context, inject)
  }

  if (typeof nuxt_plugin_barcode_5a25c76c === 'function') {
    await nuxt_plugin_barcode_5a25c76c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_preload_350ed446 === 'function') {
    await nuxt_plugin_preload_350ed446(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_offline_62608b2c === 'function') {
    await nuxt_plugin_offline_62608b2c(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // If server-side, wait for async component to be resolved first
  if (process.server && ssrContext && ssrContext.url) {
    await new Promise((resolve, reject) => {
      router.push(ssrContext.url, resolve, (err) => {
        // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
        if (!err._isRouter) return reject(err)
        if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

        // navigated to a different route in router guard
        const unregister = router.afterEach(async (to, from) => {
          ssrContext.url = to.fullPath
          app.context.route = await getRouteData(to)
          app.context.params = to.params || {}
          app.context.query = to.query || {}
          unregister()
          resolve()
        })
      })
    })
  }

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
